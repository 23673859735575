<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
          <div
            class="w-full p-px bg-gradient-to-r from-red-700 to-green-500 rounded-3xl shadow-xl"
          >
            <div
              class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl text-yellow-50 bg-gray-900"
            >
              <div class="flex flex-col justify-center space-y-3">
                <div class="bg-gray-800 py-2 px-3 rounded">
                  <div class="text-base font-medium">
                    <i class="fad fa-wallet mr-2 z-0"></i>
                    <span
                      class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-400"
                    >
                      {{ getUser.address.slice(0, 12) }}...{{
                        getUser.address.slice(-10)
                      }}
                    </span>
                  </div>
                </div>
                <div class="flex items-center space-x-3 gap-2">
                  <a
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 space-x-1 flex items-center justify-center text-blue-400"
                    v-bind:href="`https://${EXPLORER}/address/${getUser.address}`"
                    target="_blank"
                    ><i class="fad fa-link mr-2"></i> View on explorer</a
                  >

                  <div
                    class="flex items-center flex-shrink-0 space-x-1 no-underline cursor-pointer whitespace-nowrap hover:no-underline focus:no-underline active:no-underline text-blue opacity-80 hover:opacity-100 focus:opacity-100 text-blue-400"
                  >
                    <input
                      type="hidden"
                      id="text-address"
                      :value="getUser.address"
                    />
                    <a v-on:click="copyAddress()" class="text-sm font-medium"
                      ><i class="fad fa-copy"></i> {{ copymsg }}</a
                    >
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <span class="text-gray-500">Value(USD)</span>
                <h3 class="text-2xl text-shadow-white">
                  ≈ $ {{ usd(total_usd) }}
                </h3>

                <div class="flex flex-col sm:flex-row gap-2 mt-2">
                  <router-link
                    v-bind:to="'/transactions/' + getUser.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-copy mr-1"></i> Transactions</router-link
                  >

                  <router-link
                    v-bind:to="'/withdraw/' + getUser.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-clipboard-list mr-1"></i> Drive
                    history</router-link
                  >

                  <div>
                    <router-link
                      v-bind:to="'/kyc/' + getUser.address"
                      v-if="dataKYC.status == 'REJECT'"
                      class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-yellow-400 mr-3"
                    >
                      <i class="fas fa-exclamation-circle mr-1"></i> Document
                      KYC <b class="text-red-600"> ( Reject ) </b>
                    </router-link>

                    <div
                      v-else-if="dataKYC.status == 'APPROVE'"
                      class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-green-600 mr-3"
                    >
                      <i class="fas fa-grin-alt mr-1"></i> Document KYC
                    </div>

                    <div
                      v-else-if="dataKYC.status == 'WAIT'"
                      class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-yellow-400 mr-3"
                    >
                      <i class="fas fa-exclamation-circle mr-1"></i> Document
                      KYC
                      <b class="text-yellow-300"> ( Wait approve by admin ) </b>
                    </div>

                    <router-link
                      v-bind:to="'/kyc/' + getUser.address"
                      v-else
                      class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                      ><i class="fad fa-clipboard-list mr-1"></i> Document
                      KYC</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <div class="" v-on:click="hideSmall()" v-if="!hide.smallasset">
              <p class="cursor-pointer">
                <i class="fad fa-eye-slash"></i> Hide 0 balances
              </p>
            </div>
            <div class="active" v-on:click="hideSmall()" v-else>
              <p class="cursor-pointer">
                <i class="fad fa-eye"></i> Show 0 balances
              </p>
            </div>
          </div>

          <div class="p-1">
            <div v-if="getBalance && getCurrency">
              <div v-for="b in getBalance" v-bind:key="b.id" class="">
                <router-link
                  v-bind:to="'/balance/' + b.coin"
                  tag="div"
                  class="w-full"
                  v-if="b.balance > 0 || hide.smallasset"
                >
                  <div
                    class="flex border-t border-l shadow-md border-opacity-25 py-1 px-3 bg-gray-500 rounded-lg bg-opacity-20 mb-2 items-center"
                  >
                    <div class="flex-none">
                      <img
                        class="h-4 inline mr-2"
                        :src="getCurrency[b.coin].logo"
                      />
                      <span class="mr-2">{{ b.coin }}</span>
                      <span class="text-xs">{{
                        getCurrency[b.coin].name
                      }}</span>
                      <br /><span
                        class="text-xs"
                        v-if="getCurrency[b.coin].price"
                        ><span class="font-bold"
                          >${{ getCurrency[b.coin].price.last_price }}</span
                        >
                        <span
                          class="text-green-500"
                          v-if="getCurrency[b.coin].price.change / 1 >= 0"
                        >
                          +{{ getCurrency[b.coin].price.change }}%
                        </span>
                        <span class="text-red-500" v-else>
                          {{ getCurrency[b.coin].price.change }}%
                        </span>
                      </span>
                      <span class="text-xs" v-else
                        ><span class="font-bold">$0</span>
                        <span class="text-green-500"> +0% </span>
                      </span>
                    </div>

                    <div class="flex-grow text-right cursor-pointer">
                      <span class="">{{ amount(b.balance) }}</span>
                      <br />

                      <span
                        class="text-gray-500 text-xs"
                        v-if="getCurrency[b.coin].price"
                      >
                        <div
                          v-for="n in tokenBalance"
                          v-bind:key="n.name"
                          class=""
                        >
                          <div v-if="b.coin == n.name">
                            In wallet: {{ n.balance }}
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="">
      <StickFooter />
    </footer>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import func from "@/widgets/func";
import { mapActions, mapGetters } from "vuex";
import { EXPLORER, CHAIN } from "../../config";

import kyc from "@/api/kyc";

import Web3 from "web3";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  components: {
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      EXPLORER: EXPLORER,
      copymsg: "Copy Address",
      total_usd: 0,
      hide: JSON.parse(localStorage.getItem("HideAsset")) || {
        status: true,
        smallasset: true,
      },
      tokenBalance: [],
      chain: CHAIN.toLowerCase(),
      dataKYC: {},
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    ...mapActions(["fetchAssets", "fetchBalance", "fetchMarket"]),

    hideDelisted() {
      this.hide.status = !this.hide.status;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    hideSmall() {
      this.hide.smallasset = !this.hide.smallasset;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    amount(a) {
      return func.fixed8(a);
    },
    usd(a) {
      return func.fixed2(a);
    },
    caltotal() {
      var total = 0;

      for (let [coin, value] of Object.entries(this.getBalance)) {
        if (this.getCurrency[coin].price) {
          total =
            total + value.balance * this.getCurrency[coin].price.last_price;
        }
      }
      this.total_usd = total;
    },
    copyAddress() {
      let textToCopy = document.querySelector("#text-address");
      textToCopy.setAttribute("type", "text"); // hidden
      textToCopy.select();

      try {
        document.execCommand("copy");
        this.copymsg = "Copied";
        setTimeout(() => {
          this.copymsg = "Copy Address";
        }, 1000);
      } catch (err) {
        this.copymsg = "Oops, unable to copy";
      }

      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      var accounts = await ethereum.request({ method: "eth_requestAccounts" });

      for (let [coin, value] of Object.entries(this.getBalance)) {
        if (coin == "BNB" || coin == "ETH" || coin == "MATIC") {
          const ether_raw = await web3.eth.getBalance(accounts[0]);
          var amount_coin = (ether_raw / Math.pow(10, 18)).toFixed(8);

          this.tokenBalance.push({
            name: coin,
            balance: amount_coin,
          });
        } else {
          var contract_address =
            this.getCurrency[coin].address[this.chain].address;

          const tokenInst = new web3.eth.Contract(tokenABI, contract_address);
          const token_raw = await tokenInst.methods
            .balanceOf(accounts[0])
            .call();

          var amount_coin = (token_raw / Math.pow(10, 18)).toFixed(8);

          this.tokenBalance.push({
            name: coin,
            balance: amount_coin,
          });
        }
      }
    },
    async getDataKYC() {
      await kyc
        .byUser({
          address: this.getUser.address,
        })
        .then((res) => {
          this.dataKYC = res.data.data;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.caltotal();
    this.getTokenBalance();
    this.getDataKYC();
  },
  updated() {
    // this.caltota/l();
  },
};
</script>
